import { default as aboutHqt97OqhogMeta } from "/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue?macro=true";
import { default as eventsJvTmL4Pfo6Meta } from "/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93Pz0u7ShaIBMeta } from "/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45samplejeaxdsbRrkMeta } from "/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productsiUuWkDPggXMeta } from "/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93TQh9JP7fblMeta } from "/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as view519oB5sT3WMeta } from "/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexp1ors7nl3hMeta } from "/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93mYFx3YSzoeMeta } from "/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexifF7d9XBQDMeta } from "/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93KqY9hvKhWsMeta } from "/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as indexqU1cDY6CnGMeta } from "/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionsVooNaaZ0mDMeta } from "/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordingsIzOqqZaEAvMeta } from "/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsrZOQ5JPoADMeta } from "/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93BCOuZJgNjnMeta } from "/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue?macro=true";
import { default as indexwhMoaeYAt8Meta } from "/tmp/build_2c448860/pages/[studio_url]/buy/product/[product_id]/index.vue?macro=true";
import { default as confirmO5YIiFBwp1Meta } from "/tmp/build_2c448860/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue?macro=true";
import { default as confirmTMQhSoJR1XMeta } from "/tmp/build_2c448860/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue?macro=true";
import { default as calendar9Mm0NkgZ9CMeta } from "/tmp/build_2c448860/pages/[studio_url]/calendar.vue?macro=true";
import { default as chattr2lGrekQEMeta } from "/tmp/build_2c448860/pages/[studio_url]/chat.vue?macro=true";
import { default as aboutJSMgxStjX4Meta } from "/tmp/build_2c448860/pages/[studio_url]/community-groups/[group_id]/about.vue?macro=true";
import { default as feedHXZh2Sdqv2Meta } from "/tmp/build_2c448860/pages/[studio_url]/community-groups/[group_id]/feed.vue?macro=true";
import { default as membersRQgaksLr8pMeta } from "/tmp/build_2c448860/pages/[studio_url]/community-groups/[group_id]/members.vue?macro=true";
import { default as _91group_id_93q4gFWNCRpoMeta } from "/tmp/build_2c448860/pages/[studio_url]/community-groups/[group_id].vue?macro=true";
import { default as community_45profile_45editsOXvMvZ3jLMeta } from "/tmp/build_2c448860/pages/[studio_url]/community-profile-edit.vue?macro=true";
import { default as _91id_93vGQr86uXNHMeta } from "/tmp/build_2c448860/pages/[studio_url]/community-profile/[id].vue?macro=true";
import { default as communityjs0K34I4xqMeta } from "/tmp/build_2c448860/pages/[studio_url]/community.vue?macro=true";
import { default as _91uuid_93E0egeVXAmgMeta } from "/tmp/build_2c448860/pages/[studio_url]/complete-registration/[uuid].vue?macro=true";
import { default as _91unique_id_93UPSxRTQOqYMeta } from "/tmp/build_2c448860/pages/[studio_url]/contracts/[unique_id].vue?macro=true";
import { default as dashboardl18vaWMFvjMeta } from "/tmp/build_2c448860/pages/[studio_url]/dashboard.vue?macro=true";
import { default as indexBR3BgMQxkMMeta } from "/tmp/build_2c448860/pages/[studio_url]/event/details/[event_id]/index.vue?macro=true";
import { default as checkoutBmAeA0ICn4Meta } from "/tmp/build_2c448860/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue?macro=true";
import { default as select_45productTD435KR7FGMeta } from "/tmp/build_2c448860/pages/[studio_url]/event/details/[event_id]/select-product.vue?macro=true";
import { default as eventsTGgzJIxAgfMeta } from "/tmp/build_2c448860/pages/[studio_url]/events.vue?macro=true";
import { default as _91uuid_93V5mVpu5YRsMeta } from "/tmp/build_2c448860/pages/[studio_url]/gifts/[uuid].vue?macro=true";
import { default as indexit4l1dOEAkMeta } from "/tmp/build_2c448860/pages/[studio_url]/gifts/index.vue?macro=true";
import { default as _91invitation_id_93wwWvYobvO4Meta } from "/tmp/build_2c448860/pages/[studio_url]/gifts/invitation/[invitation_id].vue?macro=true";
import { default as _91product_id_932gO7DLKyH7Meta } from "/tmp/build_2c448860/pages/[studio_url]/gifts/product/[product_id].vue?macro=true";
import { default as indexEFm3tX2MuFMeta } from "/tmp/build_2c448860/pages/[studio_url]/index.vue?macro=true";
import { default as _91invitation_unique_id_93vIPdBgoGEXMeta } from "/tmp/build_2c448860/pages/[studio_url]/invitation/[invitation_unique_id].vue?macro=true";
import { default as joinSNGEKK4QbqMeta } from "/tmp/build_2c448860/pages/[studio_url]/join.vue?macro=true";
import { default as logink6wXhgTAbzMeta } from "/tmp/build_2c448860/pages/[studio_url]/login.vue?macro=true";
import { default as logoutb9l6LQ4EuGMeta } from "/tmp/build_2c448860/pages/[studio_url]/logout.vue?macro=true";
import { default as _91meeting_type_slug_936CYvh48oSUMeta } from "/tmp/build_2c448860/pages/[studio_url]/meet/[meeting_type_slug].vue?macro=true";
import { default as _91meeting_unique_id_934Fcuf9wilSMeta } from "/tmp/build_2c448860/pages/[studio_url]/meet/[meeting_unique_id].vue?macro=true";
import { default as _91meeting_unique_id_938JS3yvRdKsMeta } from "/tmp/build_2c448860/pages/[studio_url]/meeting/[meeting_unique_id].vue?macro=true";
import { default as my_45favorites1B77M8mZfUMeta } from "/tmp/build_2c448860/pages/[studio_url]/my-favorites.vue?macro=true";
import { default as my_45studios0U6jN8G0gGMeta } from "/tmp/build_2c448860/pages/[studio_url]/my-studios.vue?macro=true";
import { default as optinbaBcDFVb18Meta } from "/tmp/build_2c448860/pages/[studio_url]/optin.vue?macro=true";
import { default as _91episode_id_93ok01giseP4Meta } from "/tmp/build_2c448860/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue?macro=true";
import { default as indexkyHMVEmieuMeta } from "/tmp/build_2c448860/pages/[studio_url]/podcasts/[podcast_id]/index.vue?macro=true";
import { default as indexFHlNmgcxpeMeta } from "/tmp/build_2c448860/pages/[studio_url]/podcasts/index.vue?macro=true";
import { default as about0o48F00YeKMeta } from "/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/about.vue?macro=true";
import { default as eventsvbSHCXOhJAMeta } from "/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93NTBy0KqqFkMeta } from "/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45samplecjsHQMYI9XMeta } from "/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productsWa4ygNbGYOMeta } from "/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93F2ZL145mzKMeta } from "/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewCnalAF6Q9bMeta } from "/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexLsvrzEDirrMeta } from "/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93mAPKGpKztCMeta } from "/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexkAiSkqIr8jMeta } from "/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93EAgPEh06hRMeta } from "/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as indeximY8Ery7eZMeta } from "/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionsrrF3v4ozA0Meta } from "/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordingshSAwoJjd77Meta } from "/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsZ2woFA0p0jMeta } from "/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93zQY2rMJ1i8Meta } from "/tmp/build_2c448860/pages/[studio_url]/product/[product_id].vue?macro=true";
import { default as indexC0E27ZqckrMeta } from "/tmp/build_2c448860/pages/[studio_url]/product/index.vue?macro=true";
import { default as indexXnKpUeH3hzMeta } from "/tmp/build_2c448860/pages/[studio_url]/profile/index.vue?macro=true";
import { default as purchasesUGvuCqogS4Meta } from "/tmp/build_2c448860/pages/[studio_url]/profile/purchases.vue?macro=true";
import { default as redemptionsMpUdIazJktMeta } from "/tmp/build_2c448860/pages/[studio_url]/profile/redemptions.vue?macro=true";
import { default as _91quiz_id_935yJaNAU15yMeta } from "/tmp/build_2c448860/pages/[studio_url]/quiz/[quiz_id].vue?macro=true";
import { default as _91token_93n7uhL56ONMMeta } from "/tmp/build_2c448860/pages/[studio_url]/reset-password/[uid]/[token].vue?macro=true";
import { default as resetNk8arDPUCkMeta } from "/tmp/build_2c448860/pages/[studio_url]/reset.vue?macro=true";
import { default as shopROpBwl81QBMeta } from "/tmp/build_2c448860/pages/[studio_url]/shop.vue?macro=true";
import { default as signupHQ8eKVeDoiMeta } from "/tmp/build_2c448860/pages/[studio_url]/signup.vue?macro=true";
import { default as live8nW9elv0V4Meta } from "/tmp/build_2c448860/pages/[studio_url]/streaming/[event_id]/live.vue?macro=true";
import { default as tipsEgiBoFUpiQMeta } from "/tmp/build_2c448860/pages/[studio_url]/tips.vue?macro=true";
import { default as _91uuid_93UPpE0BxBVDMeta } from "/tmp/build_2c448860/pages/[studio_url]/waiver/[uuid].vue?macro=true";
import { default as compactgVeICgLCWpMeta } from "/tmp/build_2c448860/pages/[studio_url]/widgets/calendars/compact.vue?macro=true";
import { default as full8G0uZjMByfMeta } from "/tmp/build_2c448860/pages/[studio_url]/widgets/calendars/full.vue?macro=true";
export default [
  {
    name: _91product_id_93BCOuZJgNjnMeta?.name ?? "bundle-bundle_id-product-product_id",
    path: _91product_id_93BCOuZJgNjnMeta?.path ?? "/bundle/:bundle_id()/product/:product_id()",
    meta: _91product_id_93BCOuZJgNjnMeta || {},
    alias: _91product_id_93BCOuZJgNjnMeta?.alias || [],
    redirect: _91product_id_93BCOuZJgNjnMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutHqt97OqhogMeta?.name ?? "bundle-bundle_id-product-product_id-about",
    path: aboutHqt97OqhogMeta?.path ?? "about",
    meta: aboutHqt97OqhogMeta || {},
    alias: aboutHqt97OqhogMeta?.alias || [],
    redirect: aboutHqt97OqhogMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: eventsJvTmL4Pfo6Meta?.name ?? "bundle-bundle_id-product-product_id-events",
    path: eventsJvTmL4Pfo6Meta?.path ?? "events",
    meta: eventsJvTmL4Pfo6Meta || {},
    alias: eventsJvTmL4Pfo6Meta?.alias || [],
    redirect: eventsJvTmL4Pfo6Meta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93Pz0u7ShaIBMeta?.name ?? "bundle-bundle_id-product-product_id-free-media-media_id",
    path: _91media_id_93Pz0u7ShaIBMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93Pz0u7ShaIBMeta || {},
    alias: _91media_id_93Pz0u7ShaIBMeta?.alias || [],
    redirect: _91media_id_93Pz0u7ShaIBMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45samplejeaxdsbRrkMeta?.name ?? "bundle-bundle_id-product-product_id-free-sample",
    path: free_45samplejeaxdsbRrkMeta?.path ?? "free-sample",
    meta: free_45samplejeaxdsbRrkMeta || {},
    alias: free_45samplejeaxdsbRrkMeta?.alias || [],
    redirect: free_45samplejeaxdsbRrkMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productsiUuWkDPggXMeta?.name ?? "bundle-bundle_id-product-product_id-included-products",
    path: included_45productsiUuWkDPggXMeta?.path ?? "included-products",
    meta: included_45productsiUuWkDPggXMeta || {},
    alias: included_45productsiUuWkDPggXMeta?.alias || [],
    redirect: included_45productsiUuWkDPggXMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93TQh9JP7fblMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93TQh9JP7fblMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93TQh9JP7fblMeta || {},
    alias: _91media_id_93TQh9JP7fblMeta?.alias || [],
    redirect: _91media_id_93TQh9JP7fblMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: view519oB5sT3WMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-view",
    path: view519oB5sT3WMeta?.path ?? "lessons/:lesson_id()/view",
    meta: view519oB5sT3WMeta || {},
    alias: view519oB5sT3WMeta?.alias || [],
    redirect: view519oB5sT3WMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexp1ors7nl3hMeta?.name ?? "bundle-bundle_id-product-product_id-lessons",
    path: indexp1ors7nl3hMeta?.path ?? "lessons",
    meta: indexp1ors7nl3hMeta || {},
    alias: indexp1ors7nl3hMeta?.alias || [],
    redirect: indexp1ors7nl3hMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93mYFx3YSzoeMeta?.name ?? "bundle-bundle_id-product-product_id-media-media_id",
    path: _91media_id_93mYFx3YSzoeMeta?.path ?? "media/:media_id()",
    meta: _91media_id_93mYFx3YSzoeMeta || {},
    alias: _91media_id_93mYFx3YSzoeMeta?.alias || [],
    redirect: _91media_id_93mYFx3YSzoeMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexifF7d9XBQDMeta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id",
    path: indexifF7d9XBQDMeta?.path ?? "playlists/:playlist_id()",
    meta: indexifF7d9XBQDMeta || {},
    alias: indexifF7d9XBQDMeta?.alias || [],
    redirect: indexifF7d9XBQDMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93KqY9hvKhWsMeta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93KqY9hvKhWsMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93KqY9hvKhWsMeta || {},
    alias: _91media_id_93KqY9hvKhWsMeta?.alias || [],
    redirect: _91media_id_93KqY9hvKhWsMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexqU1cDY6CnGMeta?.name ?? "bundle-bundle_id-product-product_id-playlists",
    path: indexqU1cDY6CnGMeta?.path ?? "playlists",
    meta: indexqU1cDY6CnGMeta || {},
    alias: indexqU1cDY6CnGMeta?.alias || [],
    redirect: indexqU1cDY6CnGMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionsVooNaaZ0mDMeta?.name ?? "bundle-bundle_id-product-product_id-private-sessions",
    path: private_45sessionsVooNaaZ0mDMeta?.path ?? "private-sessions",
    meta: private_45sessionsVooNaaZ0mDMeta || {},
    alias: private_45sessionsVooNaaZ0mDMeta?.alias || [],
    redirect: private_45sessionsVooNaaZ0mDMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordingsIzOqqZaEAvMeta?.name ?? "bundle-bundle_id-product-product_id-recordings",
    path: recordingsIzOqqZaEAvMeta?.path ?? "recordings",
    meta: recordingsIzOqqZaEAvMeta || {},
    alias: recordingsIzOqqZaEAvMeta?.alias || [],
    redirect: recordingsIzOqqZaEAvMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsrZOQ5JPoADMeta?.name ?? "bundle-bundle_id-product-product_id-reviews",
    path: reviewsrZOQ5JPoADMeta?.path ?? "reviews",
    meta: reviewsrZOQ5JPoADMeta || {},
    alias: reviewsrZOQ5JPoADMeta?.alias || [],
    redirect: reviewsrZOQ5JPoADMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexwhMoaeYAt8Meta?.name ?? "buy-product-product_id",
    path: indexwhMoaeYAt8Meta?.path ?? "/buy/product/:product_id()",
    meta: indexwhMoaeYAt8Meta || {},
    alias: indexwhMoaeYAt8Meta?.alias || [],
    redirect: indexwhMoaeYAt8Meta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/buy/product/[product_id]/index.vue").then(m => m.default || m)
  },
  {
    name: confirmO5YIiFBwp1Meta?.name ?? "buy-product-product_id-paypal-confirm",
    path: confirmO5YIiFBwp1Meta?.path ?? "/buy/product/:product_id()/paypal/confirm",
    meta: confirmO5YIiFBwp1Meta || {},
    alias: confirmO5YIiFBwp1Meta?.alias || [],
    redirect: confirmO5YIiFBwp1Meta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmTMQhSoJR1XMeta?.name ?? "buy-product-product_id-stripe-confirm",
    path: confirmTMQhSoJR1XMeta?.path ?? "/buy/product/:product_id()/stripe/confirm",
    meta: confirmTMQhSoJR1XMeta || {},
    alias: confirmTMQhSoJR1XMeta?.alias || [],
    redirect: confirmTMQhSoJR1XMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue").then(m => m.default || m)
  },
  {
    name: calendar9Mm0NkgZ9CMeta?.name ?? "calendar",
    path: calendar9Mm0NkgZ9CMeta?.path ?? "/calendar",
    meta: calendar9Mm0NkgZ9CMeta || {},
    alias: calendar9Mm0NkgZ9CMeta?.alias || [],
    redirect: calendar9Mm0NkgZ9CMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/calendar.vue").then(m => m.default || m)
  },
  {
    name: chattr2lGrekQEMeta?.name ?? "chat",
    path: chattr2lGrekQEMeta?.path ?? "/chat",
    meta: chattr2lGrekQEMeta || {},
    alias: chattr2lGrekQEMeta?.alias || [],
    redirect: chattr2lGrekQEMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/chat.vue").then(m => m.default || m)
  },
  {
    name: _91group_id_93q4gFWNCRpoMeta?.name ?? "community-groups-group_id",
    path: _91group_id_93q4gFWNCRpoMeta?.path ?? "/community-groups/:group_id()",
    meta: _91group_id_93q4gFWNCRpoMeta || {},
    alias: _91group_id_93q4gFWNCRpoMeta?.alias || [],
    redirect: _91group_id_93q4gFWNCRpoMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/community-groups/[group_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutJSMgxStjX4Meta?.name ?? "community-groups-group_id-about",
    path: aboutJSMgxStjX4Meta?.path ?? "about",
    meta: aboutJSMgxStjX4Meta || {},
    alias: aboutJSMgxStjX4Meta?.alias || [],
    redirect: aboutJSMgxStjX4Meta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/community-groups/[group_id]/about.vue").then(m => m.default || m)
  },
  {
    name: feedHXZh2Sdqv2Meta?.name ?? "community-groups-group_id-feed",
    path: feedHXZh2Sdqv2Meta?.path ?? "feed",
    meta: feedHXZh2Sdqv2Meta || {},
    alias: feedHXZh2Sdqv2Meta?.alias || [],
    redirect: feedHXZh2Sdqv2Meta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/community-groups/[group_id]/feed.vue").then(m => m.default || m)
  },
  {
    name: membersRQgaksLr8pMeta?.name ?? "community-groups-group_id-members",
    path: membersRQgaksLr8pMeta?.path ?? "members",
    meta: membersRQgaksLr8pMeta || {},
    alias: membersRQgaksLr8pMeta?.alias || [],
    redirect: membersRQgaksLr8pMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/community-groups/[group_id]/members.vue").then(m => m.default || m)
  }
]
  },
  {
    name: community_45profile_45editsOXvMvZ3jLMeta?.name ?? "community-profile-edit",
    path: community_45profile_45editsOXvMvZ3jLMeta?.path ?? "/community-profile-edit",
    meta: community_45profile_45editsOXvMvZ3jLMeta || {},
    alias: community_45profile_45editsOXvMvZ3jLMeta?.alias || [],
    redirect: community_45profile_45editsOXvMvZ3jLMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/community-profile-edit.vue").then(m => m.default || m)
  },
  {
    name: _91id_93vGQr86uXNHMeta?.name ?? "community-profile-id",
    path: _91id_93vGQr86uXNHMeta?.path ?? "/community-profile/:id()",
    meta: _91id_93vGQr86uXNHMeta || {},
    alias: _91id_93vGQr86uXNHMeta?.alias || [],
    redirect: _91id_93vGQr86uXNHMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/community-profile/[id].vue").then(m => m.default || m)
  },
  {
    name: communityjs0K34I4xqMeta?.name ?? "community",
    path: communityjs0K34I4xqMeta?.path ?? "/community",
    meta: communityjs0K34I4xqMeta || {},
    alias: communityjs0K34I4xqMeta?.alias || [],
    redirect: communityjs0K34I4xqMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/community.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93E0egeVXAmgMeta?.name ?? "complete-registration-uuid",
    path: _91uuid_93E0egeVXAmgMeta?.path ?? "/complete-registration/:uuid()",
    meta: _91uuid_93E0egeVXAmgMeta || {},
    alias: _91uuid_93E0egeVXAmgMeta?.alias || [],
    redirect: _91uuid_93E0egeVXAmgMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/complete-registration/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91unique_id_93UPSxRTQOqYMeta?.name ?? "contracts-unique_id",
    path: _91unique_id_93UPSxRTQOqYMeta?.path ?? "/contracts/:unique_id()",
    meta: _91unique_id_93UPSxRTQOqYMeta || {},
    alias: _91unique_id_93UPSxRTQOqYMeta?.alias || [],
    redirect: _91unique_id_93UPSxRTQOqYMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/contracts/[unique_id].vue").then(m => m.default || m)
  },
  {
    name: dashboardl18vaWMFvjMeta?.name ?? "dashboard",
    path: dashboardl18vaWMFvjMeta?.path ?? "/dashboard",
    meta: dashboardl18vaWMFvjMeta || {},
    alias: dashboardl18vaWMFvjMeta?.alias || [],
    redirect: dashboardl18vaWMFvjMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexBR3BgMQxkMMeta?.name ?? "event-details-event_id",
    path: indexBR3BgMQxkMMeta?.path ?? "/event/details/:event_id()",
    meta: indexBR3BgMQxkMMeta || {},
    alias: indexBR3BgMQxkMMeta?.alias || [],
    redirect: indexBR3BgMQxkMMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/event/details/[event_id]/index.vue").then(m => m.default || m)
  },
  {
    name: checkoutBmAeA0ICn4Meta?.name ?? "event-details-event_id-product-product_id-checkout",
    path: checkoutBmAeA0ICn4Meta?.path ?? "/event/details/:event_id()/product/:product_id()/checkout",
    meta: checkoutBmAeA0ICn4Meta || {},
    alias: checkoutBmAeA0ICn4Meta?.alias || [],
    redirect: checkoutBmAeA0ICn4Meta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue").then(m => m.default || m)
  },
  {
    name: select_45productTD435KR7FGMeta?.name ?? "event-details-event_id-select-product",
    path: select_45productTD435KR7FGMeta?.path ?? "/event/details/:event_id()/select-product",
    meta: select_45productTD435KR7FGMeta || {},
    alias: select_45productTD435KR7FGMeta?.alias || [],
    redirect: select_45productTD435KR7FGMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/event/details/[event_id]/select-product.vue").then(m => m.default || m)
  },
  {
    name: eventsTGgzJIxAgfMeta?.name ?? "events",
    path: eventsTGgzJIxAgfMeta?.path ?? "/events",
    meta: eventsTGgzJIxAgfMeta || {},
    alias: eventsTGgzJIxAgfMeta?.alias || [],
    redirect: eventsTGgzJIxAgfMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/events.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93V5mVpu5YRsMeta?.name ?? "gifts-uuid",
    path: _91uuid_93V5mVpu5YRsMeta?.path ?? "/gifts/:uuid()",
    meta: _91uuid_93V5mVpu5YRsMeta || {},
    alias: _91uuid_93V5mVpu5YRsMeta?.alias || [],
    redirect: _91uuid_93V5mVpu5YRsMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/gifts/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexit4l1dOEAkMeta?.name ?? "gifts",
    path: indexit4l1dOEAkMeta?.path ?? "/gifts",
    meta: indexit4l1dOEAkMeta || {},
    alias: indexit4l1dOEAkMeta?.alias || [],
    redirect: indexit4l1dOEAkMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/gifts/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_id_93wwWvYobvO4Meta?.name ?? "gifts-invitation-invitation_id",
    path: _91invitation_id_93wwWvYobvO4Meta?.path ?? "/gifts/invitation/:invitation_id()",
    meta: _91invitation_id_93wwWvYobvO4Meta || {},
    alias: _91invitation_id_93wwWvYobvO4Meta?.alias || [],
    redirect: _91invitation_id_93wwWvYobvO4Meta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/gifts/invitation/[invitation_id].vue").then(m => m.default || m)
  },
  {
    name: _91product_id_932gO7DLKyH7Meta?.name ?? "gifts-product-product_id",
    path: _91product_id_932gO7DLKyH7Meta?.path ?? "/gifts/product/:product_id()",
    meta: _91product_id_932gO7DLKyH7Meta || {},
    alias: _91product_id_932gO7DLKyH7Meta?.alias || [],
    redirect: _91product_id_932gO7DLKyH7Meta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/gifts/product/[product_id].vue").then(m => m.default || m)
  },
  {
    name: indexEFm3tX2MuFMeta?.name ?? "index",
    path: indexEFm3tX2MuFMeta?.path ?? "/",
    meta: indexEFm3tX2MuFMeta || {},
    alias: indexEFm3tX2MuFMeta?.alias || [],
    redirect: indexEFm3tX2MuFMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_unique_id_93vIPdBgoGEXMeta?.name ?? "invitation-invitation_unique_id",
    path: _91invitation_unique_id_93vIPdBgoGEXMeta?.path ?? "/invitation/:invitation_unique_id()",
    meta: _91invitation_unique_id_93vIPdBgoGEXMeta || {},
    alias: _91invitation_unique_id_93vIPdBgoGEXMeta?.alias || [],
    redirect: _91invitation_unique_id_93vIPdBgoGEXMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/invitation/[invitation_unique_id].vue").then(m => m.default || m)
  },
  {
    name: joinSNGEKK4QbqMeta?.name ?? "join",
    path: joinSNGEKK4QbqMeta?.path ?? "/join",
    meta: joinSNGEKK4QbqMeta || {},
    alias: joinSNGEKK4QbqMeta?.alias || [],
    redirect: joinSNGEKK4QbqMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/join.vue").then(m => m.default || m)
  },
  {
    name: logink6wXhgTAbzMeta?.name ?? "login",
    path: logink6wXhgTAbzMeta?.path ?? "/login",
    meta: logink6wXhgTAbzMeta || {},
    alias: logink6wXhgTAbzMeta?.alias || [],
    redirect: logink6wXhgTAbzMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/login.vue").then(m => m.default || m)
  },
  {
    name: logoutb9l6LQ4EuGMeta?.name ?? "logout",
    path: logoutb9l6LQ4EuGMeta?.path ?? "/logout",
    meta: logoutb9l6LQ4EuGMeta || {},
    alias: logoutb9l6LQ4EuGMeta?.alias || [],
    redirect: logoutb9l6LQ4EuGMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/logout.vue").then(m => m.default || m)
  },
  {
    name: _91meeting_type_slug_936CYvh48oSUMeta?.name ?? "meet-meeting_type_slug",
    path: _91meeting_type_slug_936CYvh48oSUMeta?.path ?? "/meet/:meeting_type_slug()",
    meta: _91meeting_type_slug_936CYvh48oSUMeta || {},
    alias: _91meeting_type_slug_936CYvh48oSUMeta?.alias || [],
    redirect: _91meeting_type_slug_936CYvh48oSUMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/meet/[meeting_type_slug].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_934Fcuf9wilSMeta?.name ?? "meet-meeting_unique_id",
    path: _91meeting_unique_id_934Fcuf9wilSMeta?.path ?? "/meet/:meeting_unique_id()",
    meta: _91meeting_unique_id_934Fcuf9wilSMeta || {},
    alias: _91meeting_unique_id_934Fcuf9wilSMeta?.alias || [],
    redirect: _91meeting_unique_id_934Fcuf9wilSMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/meet/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_938JS3yvRdKsMeta?.name ?? "meeting-meeting_unique_id",
    path: _91meeting_unique_id_938JS3yvRdKsMeta?.path ?? "/meeting/:meeting_unique_id()",
    meta: _91meeting_unique_id_938JS3yvRdKsMeta || {},
    alias: _91meeting_unique_id_938JS3yvRdKsMeta?.alias || [],
    redirect: _91meeting_unique_id_938JS3yvRdKsMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/meeting/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: my_45favorites1B77M8mZfUMeta?.name ?? "my-favorites",
    path: my_45favorites1B77M8mZfUMeta?.path ?? "/my-favorites",
    meta: my_45favorites1B77M8mZfUMeta || {},
    alias: my_45favorites1B77M8mZfUMeta?.alias || [],
    redirect: my_45favorites1B77M8mZfUMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: my_45studios0U6jN8G0gGMeta?.name ?? "my-studios",
    path: my_45studios0U6jN8G0gGMeta?.path ?? "/my-studios",
    meta: my_45studios0U6jN8G0gGMeta || {},
    alias: my_45studios0U6jN8G0gGMeta?.alias || [],
    redirect: my_45studios0U6jN8G0gGMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/my-studios.vue").then(m => m.default || m)
  },
  {
    name: optinbaBcDFVb18Meta?.name ?? "optin",
    path: optinbaBcDFVb18Meta?.path ?? "/optin",
    meta: optinbaBcDFVb18Meta || {},
    alias: optinbaBcDFVb18Meta?.alias || [],
    redirect: optinbaBcDFVb18Meta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/optin.vue").then(m => m.default || m)
  },
  {
    name: _91episode_id_93ok01giseP4Meta?.name ?? "podcasts-podcast_id-episodes-episode_id",
    path: _91episode_id_93ok01giseP4Meta?.path ?? "/podcasts/:podcast_id()/episodes/:episode_id()",
    meta: _91episode_id_93ok01giseP4Meta || {},
    alias: _91episode_id_93ok01giseP4Meta?.alias || [],
    redirect: _91episode_id_93ok01giseP4Meta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue").then(m => m.default || m)
  },
  {
    name: indexkyHMVEmieuMeta?.name ?? "podcasts-podcast_id",
    path: indexkyHMVEmieuMeta?.path ?? "/podcasts/:podcast_id()",
    meta: indexkyHMVEmieuMeta || {},
    alias: indexkyHMVEmieuMeta?.alias || [],
    redirect: indexkyHMVEmieuMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/podcasts/[podcast_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexFHlNmgcxpeMeta?.name ?? "podcasts",
    path: indexFHlNmgcxpeMeta?.path ?? "/podcasts",
    meta: indexFHlNmgcxpeMeta || {},
    alias: indexFHlNmgcxpeMeta?.alias || [],
    redirect: indexFHlNmgcxpeMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/podcasts/index.vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93zQY2rMJ1i8Meta?.name ?? "product-product_id",
    path: _91product_id_93zQY2rMJ1i8Meta?.path ?? "/product/:product_id()",
    meta: _91product_id_93zQY2rMJ1i8Meta || {},
    alias: _91product_id_93zQY2rMJ1i8Meta?.alias || [],
    redirect: _91product_id_93zQY2rMJ1i8Meta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: about0o48F00YeKMeta?.name ?? "product-product_id-about",
    path: about0o48F00YeKMeta?.path ?? "about",
    meta: about0o48F00YeKMeta || {},
    alias: about0o48F00YeKMeta?.alias || [],
    redirect: about0o48F00YeKMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: eventsvbSHCXOhJAMeta?.name ?? "product-product_id-events",
    path: eventsvbSHCXOhJAMeta?.path ?? "events",
    meta: eventsvbSHCXOhJAMeta || {},
    alias: eventsvbSHCXOhJAMeta?.alias || [],
    redirect: eventsvbSHCXOhJAMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93NTBy0KqqFkMeta?.name ?? "product-product_id-free-media-media_id",
    path: _91media_id_93NTBy0KqqFkMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93NTBy0KqqFkMeta || {},
    alias: _91media_id_93NTBy0KqqFkMeta?.alias || [],
    redirect: _91media_id_93NTBy0KqqFkMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45samplecjsHQMYI9XMeta?.name ?? "product-product_id-free-sample",
    path: free_45samplecjsHQMYI9XMeta?.path ?? "free-sample",
    meta: free_45samplecjsHQMYI9XMeta || {},
    alias: free_45samplecjsHQMYI9XMeta?.alias || [],
    redirect: free_45samplecjsHQMYI9XMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productsWa4ygNbGYOMeta?.name ?? "product-product_id-included-products",
    path: included_45productsWa4ygNbGYOMeta?.path ?? "included-products",
    meta: included_45productsWa4ygNbGYOMeta || {},
    alias: included_45productsWa4ygNbGYOMeta?.alias || [],
    redirect: included_45productsWa4ygNbGYOMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93F2ZL145mzKMeta?.name ?? "product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93F2ZL145mzKMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93F2ZL145mzKMeta || {},
    alias: _91media_id_93F2ZL145mzKMeta?.alias || [],
    redirect: _91media_id_93F2ZL145mzKMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewCnalAF6Q9bMeta?.name ?? "product-product_id-lessons-lesson_id-view",
    path: viewCnalAF6Q9bMeta?.path ?? "lessons/:lesson_id()/view",
    meta: viewCnalAF6Q9bMeta || {},
    alias: viewCnalAF6Q9bMeta?.alias || [],
    redirect: viewCnalAF6Q9bMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexLsvrzEDirrMeta?.name ?? "product-product_id-lessons",
    path: indexLsvrzEDirrMeta?.path ?? "lessons",
    meta: indexLsvrzEDirrMeta || {},
    alias: indexLsvrzEDirrMeta?.alias || [],
    redirect: indexLsvrzEDirrMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93mAPKGpKztCMeta?.name ?? "product-product_id-media-media_id",
    path: _91media_id_93mAPKGpKztCMeta?.path ?? "media/:media_id()",
    meta: _91media_id_93mAPKGpKztCMeta || {},
    alias: _91media_id_93mAPKGpKztCMeta?.alias || [],
    redirect: _91media_id_93mAPKGpKztCMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexkAiSkqIr8jMeta?.name ?? "product-product_id-playlists-playlist_id",
    path: indexkAiSkqIr8jMeta?.path ?? "playlists/:playlist_id()",
    meta: indexkAiSkqIr8jMeta || {},
    alias: indexkAiSkqIr8jMeta?.alias || [],
    redirect: indexkAiSkqIr8jMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93EAgPEh06hRMeta?.name ?? "product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93EAgPEh06hRMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93EAgPEh06hRMeta || {},
    alias: _91media_id_93EAgPEh06hRMeta?.alias || [],
    redirect: _91media_id_93EAgPEh06hRMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indeximY8Ery7eZMeta?.name ?? "product-product_id-playlists",
    path: indeximY8Ery7eZMeta?.path ?? "playlists",
    meta: indeximY8Ery7eZMeta || {},
    alias: indeximY8Ery7eZMeta?.alias || [],
    redirect: indeximY8Ery7eZMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionsrrF3v4ozA0Meta?.name ?? "product-product_id-private-sessions",
    path: private_45sessionsrrF3v4ozA0Meta?.path ?? "private-sessions",
    meta: private_45sessionsrrF3v4ozA0Meta || {},
    alias: private_45sessionsrrF3v4ozA0Meta?.alias || [],
    redirect: private_45sessionsrrF3v4ozA0Meta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordingshSAwoJjd77Meta?.name ?? "product-product_id-recordings",
    path: recordingshSAwoJjd77Meta?.path ?? "recordings",
    meta: recordingshSAwoJjd77Meta || {},
    alias: recordingshSAwoJjd77Meta?.alias || [],
    redirect: recordingshSAwoJjd77Meta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsZ2woFA0p0jMeta?.name ?? "product-product_id-reviews",
    path: reviewsZ2woFA0p0jMeta?.path ?? "reviews",
    meta: reviewsZ2woFA0p0jMeta || {},
    alias: reviewsZ2woFA0p0jMeta?.alias || [],
    redirect: reviewsZ2woFA0p0jMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexC0E27ZqckrMeta?.name ?? "product",
    path: indexC0E27ZqckrMeta?.path ?? "/product",
    meta: indexC0E27ZqckrMeta || {},
    alias: indexC0E27ZqckrMeta?.alias || [],
    redirect: indexC0E27ZqckrMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/product/index.vue").then(m => m.default || m)
  },
  {
    name: indexXnKpUeH3hzMeta?.name ?? "profile",
    path: indexXnKpUeH3hzMeta?.path ?? "/profile",
    meta: indexXnKpUeH3hzMeta || {},
    alias: indexXnKpUeH3hzMeta?.alias || [],
    redirect: indexXnKpUeH3hzMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/profile/index.vue").then(m => m.default || m)
  },
  {
    name: purchasesUGvuCqogS4Meta?.name ?? "profile-purchases",
    path: purchasesUGvuCqogS4Meta?.path ?? "/profile/purchases",
    meta: purchasesUGvuCqogS4Meta || {},
    alias: purchasesUGvuCqogS4Meta?.alias || [],
    redirect: purchasesUGvuCqogS4Meta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/profile/purchases.vue").then(m => m.default || m)
  },
  {
    name: redemptionsMpUdIazJktMeta?.name ?? "profile-redemptions",
    path: redemptionsMpUdIazJktMeta?.path ?? "/profile/redemptions",
    meta: redemptionsMpUdIazJktMeta || {},
    alias: redemptionsMpUdIazJktMeta?.alias || [],
    redirect: redemptionsMpUdIazJktMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/profile/redemptions.vue").then(m => m.default || m)
  },
  {
    name: _91quiz_id_935yJaNAU15yMeta?.name ?? "quiz-quiz_id",
    path: _91quiz_id_935yJaNAU15yMeta?.path ?? "/quiz/:quiz_id()",
    meta: _91quiz_id_935yJaNAU15yMeta || {},
    alias: _91quiz_id_935yJaNAU15yMeta?.alias || [],
    redirect: _91quiz_id_935yJaNAU15yMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/quiz/[quiz_id].vue").then(m => m.default || m)
  },
  {
    name: _91token_93n7uhL56ONMMeta?.name ?? "reset-password-uid-token",
    path: _91token_93n7uhL56ONMMeta?.path ?? "/reset-password/:uid()/:token()",
    meta: _91token_93n7uhL56ONMMeta || {},
    alias: _91token_93n7uhL56ONMMeta?.alias || [],
    redirect: _91token_93n7uhL56ONMMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/reset-password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: resetNk8arDPUCkMeta?.name ?? "reset",
    path: resetNk8arDPUCkMeta?.path ?? "/reset",
    meta: resetNk8arDPUCkMeta || {},
    alias: resetNk8arDPUCkMeta?.alias || [],
    redirect: resetNk8arDPUCkMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/reset.vue").then(m => m.default || m)
  },
  {
    name: shopROpBwl81QBMeta?.name ?? "shop",
    path: shopROpBwl81QBMeta?.path ?? "/shop",
    meta: shopROpBwl81QBMeta || {},
    alias: shopROpBwl81QBMeta?.alias || [],
    redirect: shopROpBwl81QBMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/shop.vue").then(m => m.default || m)
  },
  {
    name: signupHQ8eKVeDoiMeta?.name ?? "signup",
    path: signupHQ8eKVeDoiMeta?.path ?? "/signup",
    meta: signupHQ8eKVeDoiMeta || {},
    alias: signupHQ8eKVeDoiMeta?.alias || [],
    redirect: signupHQ8eKVeDoiMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/signup.vue").then(m => m.default || m)
  },
  {
    name: live8nW9elv0V4Meta?.name ?? "streaming-event_id-live",
    path: live8nW9elv0V4Meta?.path ?? "/streaming/:event_id()/live",
    meta: live8nW9elv0V4Meta || {},
    alias: live8nW9elv0V4Meta?.alias || [],
    redirect: live8nW9elv0V4Meta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/streaming/[event_id]/live.vue").then(m => m.default || m)
  },
  {
    name: tipsEgiBoFUpiQMeta?.name ?? "tips",
    path: tipsEgiBoFUpiQMeta?.path ?? "/tips",
    meta: tipsEgiBoFUpiQMeta || {},
    alias: tipsEgiBoFUpiQMeta?.alias || [],
    redirect: tipsEgiBoFUpiQMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/tips.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93UPpE0BxBVDMeta?.name ?? "waiver-uuid",
    path: _91uuid_93UPpE0BxBVDMeta?.path ?? "/waiver/:uuid()",
    meta: _91uuid_93UPpE0BxBVDMeta || {},
    alias: _91uuid_93UPpE0BxBVDMeta?.alias || [],
    redirect: _91uuid_93UPpE0BxBVDMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/waiver/[uuid].vue").then(m => m.default || m)
  },
  {
    name: compactgVeICgLCWpMeta?.name ?? "widgets-calendars-compact",
    path: compactgVeICgLCWpMeta?.path ?? "/widgets/calendars/compact",
    meta: compactgVeICgLCWpMeta || {},
    alias: compactgVeICgLCWpMeta?.alias || [],
    redirect: compactgVeICgLCWpMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/widgets/calendars/compact.vue").then(m => m.default || m)
  },
  {
    name: full8G0uZjMByfMeta?.name ?? "widgets-calendars-full",
    path: full8G0uZjMByfMeta?.path ?? "/widgets/calendars/full",
    meta: full8G0uZjMByfMeta || {},
    alias: full8G0uZjMByfMeta?.alias || [],
    redirect: full8G0uZjMByfMeta?.redirect,
    component: () => import("/tmp/build_2c448860/pages/[studio_url]/widgets/calendars/full.vue").then(m => m.default || m)
  }
]