import validate from "/tmp/build_2c448860/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/tmp/build_2c448860/middleware/auth.global.ts";
import redirect_45global from "/tmp/build_2c448860/middleware/redirect.global.ts";
import subdomain_45redirect_45global from "/tmp/build_2c448860/middleware/subdomain-redirect.global.ts";
import manifest_45route_45rule from "/tmp/build_2c448860/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  redirect_45global,
  subdomain_45redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}